import React from 'react'
import { useNavigate } from 'react-router-dom'

const Error = () => {
    const navigate = useNavigate();
    return (
        <body style={{textAlign:"center"}}>
        <h1>Error</h1>
        <button class="form-button" style={{cursor:"pointer"}} onClick={()=>navigate("/login")}>Go To Login</button>
        
        </body>
    )
}

export default Error