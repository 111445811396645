import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import "./questionanswer.css";
import ReactMarkdown from 'react-markdown';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {BounceLoader} from 'react-spinners';

const Questions = (prop) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
 
  const api_node_prod = axios.create({
    baseURL:'https://server-chi-puce.vercel.app'
  })
  
  const api_node_local = axios.create({
  baseURL:'http://localhost:6005'
  })
  
  const api_node = api_node_prod
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await api_node.get(`/dashboard/questions?page=${currentPage}&limit=${itemsPerPage}&userid=${prop.userdata}`);
        setData(response.data.docs);
        setTotalPages(response.data.totalpages);
        setMessage(response.data.message);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage]);

  // Logic for displaying pagination buttons
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


  return (
    <div>
        <ToastContainer/>
    {/* Display your data here */}
    {isLoading && (
           
           <BounceLoader color="darkgoldenrod" />
    
    )}
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {data.map((item) => (
        <div class="questionanswercontainer">
       
        <div class="post">
          <div class="profile-pic">
            
            <img
              src={item.questionpic}
              alt={item.questionusername}
              style={{width:"50px", borderRadius:"50%"}}
            />
          </div>
          <div class="content">
            <div class="user-info">
              <span class="name">{item.questionusername}</span>
              <span style={{width:"10px",display:"inline-block"}}></span>
              <span class="timestamp">{ new Date(item.createdAt).toLocaleString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'})}</span>
            </div>
            <div class="text">
             <b> {item.question} </b>
            </div>
          </div>
          
        </div>
    
    
        <div class="post answer">
          <div class="profile-pic">
          <img
              src={item.answerpic}
              alt={item.answerusername}
              style={{width:"50px", borderRadius:"50%"}}
            />
          </div>
          <div class="content">
            <div class="user-info">
              <span class="name">{item.answerusername}</span>
              <span style={{width:"10px",display:"inline-block"}}></span>
              <span class="timestamp">{ new Date(item.updatedAt).toLocaleString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'})}</span>
            </div>
            <div className='text results-answer2'>
            <ReactMarkdown>
            {item.answer}
            </ReactMarkdown>
            </div>
          </div>
         
        </div>
      </div>
      ))}
    </div>
    {/* Display message */}
    <div style={{ margin: "20px"}}>
{message}
      </div>
    {/* Pagination buttons */}
    <div style={{ margin: "20px", paddingBottom:"60px"}}>
      {pageNumbers.map((number) => (
        <button key={number} onClick={() => setCurrentPage(number)}>
          {number}
        </button>
      ))}
    </div>
  </div>

  );
}

export default Questions;