import React from 'react';
import "./header.css";




const Footers = () => {
   
    return (
        <>
        <footer>
            Email: ivdmahesh@gmail.com
            Phone: +91-9849502694
            &nbsp; &nbsp; &nbsp; &nbsp; Copyright &copy; 2025
                  
               
        </footer>
        </>
    )
}

export default Footers