import "./map.css";
import React, { useState } from "react";
import { Map, Marker, InfoWindow} from "@vis.gl/react-google-maps";

const CustomMap = (props) => {
  // shows marker on London by default
  const [markerLocation, setMarkerLocation] = useState({
    lat: props.coordinates[0],
    lng: props.coordinates[1],
  }); 


  return (
    <div className="map-container">
      <Map
        style={{ borderRadius: "20px" }}
        defaultZoom={16}
        defaultCenter={markerLocation}
        gestureHandling={"greedy"}
      >
        <Marker position={markerLocation} />
      </Map>
      
    </div>
  );
}

export default CustomMap;