import React, {useEffect, useState} from 'react'
import "./elements.css"
import "./map.css"
import CustomMap from "./CustomMap";
import { APIProvider } from "@vis.gl/react-google-maps";
import pdfToText from 'react-pdftotext';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom"
import axios from "axios"


const Profile = () => {

  const api_python_prod = axios.create({
    baseURL:'https://genaiapp-e46e4a1cbba8.herokuapp.com'
})

const api_python_local = axios.create({
  baseURL:'http://localhost:8000'
})

const api_python = api_python_prod

const api_node_prod = axios.create({
  baseURL:'https://server-chi-puce.vercel.app'
})

const api_node_local = axios.create({
baseURL:'http://localhost:6005'
})

const api_node = api_node_prod

  const navigate=useNavigate();
  const [userdata, setUserdata] = useState({});
  const getUser = async()=> {
      try {
          const response = await api_node.get("/login/success",{withCredentials:true});
          setUserdata(response.data.user)
      }catch(error){
          navigate("*")
      }
  }

  useEffect(()=>{
      getUser()
  },[])

    const options = [
        'Volunteer', 'Tutor','Community','Business'
      ];
      const defaultOption = options[0];

    const [categoryValue, setCategoryValue] = useState(defaultOption); 
    const [nameValue, setNameValue] = useState(); 
    const [descriptionValue, setDescriptionValue] = useState();
    const [latitudeValue, setLatitudeValue] = useState();
    const [longitudeValue, setLongitudeValue] = useState();
    const [text, setText] = useState('');


  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
    };

    const handleNameChange = (event) => {
        setNameValue(event.target.value);
        };

        const handleDescriptionChange = (event) => {
            setDescriptionValue(event.target.value);
            };

            const handleLatitudeChange = (event) => {
              setLatitudeValue(event.target.value);
              };

              const handleLongitudeChange = (event) => {
                setLongitudeValue(event.target.value);
                };

            const setLatitudeLongitude = (childdata) => {
                setLatitudeValue(childdata[0]);
                setLongitudeValue(childdata[1]);
                };

                const handleFileChange = async (event) => {
                  try{
                  let selectedFile = event.target.files[0];
                  let textcontents=await pdfToText(selectedFile);
                  setText(textcontents);
                  }
                  catch(e){
                  
                  }

                };

                const handleSubmit = async (e) => {
                  e.preventDefault();
                  var regexp = /^\d+(\.\d+)?$/;
                  if(!nameValue){
                    toast.error('Name field is required');
                  }
                  else if(!descriptionValue){
                    toast.error('Description field is required');
                  }
                  else if(!regexp.test(latitudeValue)){
                    toast.error('Latitude field is required');
                  }
                  else if(!regexp.test(longitudeValue)){
                    toast.error('Longitude field is required');
                  }
                  else if(!text){
                    toast.error('pdf file is required');
                  }
                  else {

                    toast.success('Profile Saved');

                     const modifieddoc = await api_node.post("/saveprofile", {
                      googleId:userdata.googleId, 
                      profileCategory:categoryValue,
                      profileName:nameValue, 
                      profileDescription:descriptionValue, 
                      latitude:latitudeValue, 
                      longitude:longitudeValue
                    });
                    
                    
                    const storeprofileresponse = await api_python.post("/storeprofile", {
                      text:text,
                      id:userdata.googleId
                     
                    });
                    //alert(storeprofileresponse);
                    
                  }
                 
              }
          

    return (
        <body style={{textAlign:"center"}}>
          <ToastContainer/>
          <div  class="elementdiv"  style={{textAlign:"left"}}>
        <p style={{fontSize:"24px",paddingLeft:"20%", paddingRight:"20%"}}><b>Profile</b></p>
        </div>

        <div class="elementdiv">
        <label>

  <text class="label">Select Category</text>

  <select class="classic" value={categoryValue} onChange={handleCategoryChange} name="category">

    <option value="Volunteer">Volunteer</option>

    <option value="Tutor">Tutor</option>

    <option value="Community">Community</option>

    <option value="Business">Business</option>

    <option value="Inactive">Inactive</option>

  </select>

</label>
</div>
<div  class="elementdiv">
    <label>
    <text class="label">Name</text>
    <input type="text" class="textbox" name="name" onChange={handleNameChange}></input>
    </label>
</div>
<div  class="elementdiv">
    <label>
    <text class="label">Description in one line</text>
    <input type="text" class="textbox"  name="description" onChange={handleDescriptionChange}></input>
    </label>
</div>
<div  class="elementdiv" style={{maxWidth:"70%", textAlign:"center", paddingLeft:"30%"}}>
<label><text class="label">Enter latitude and longitude of your place OR click on the map below. For example latitude and longitude of Ujjain are 23.179300
and 75.784912 respectively</text></label>
</div>
<div  class="elementdiv">
    <label>
    <text class="label">Latitude</text>
    <input type="text" class="textbox" value={latitudeValue} name="latitude" onChange={handleLatitudeChange}></input>
    </label>
</div>
<div  class="elementdiv">
    <label>
    <text class="label">Longitude</text>
    <input type="text" class="textbox" value={longitudeValue} name="longitude" onChange={handleLongitudeChange}></input>
    </label>
</div>
<div className="app">
      <APIProvider apiKey="AIzaSyA0aU-z6e1-cGOEJ6J0kK-l33josqGFYrM">
        <CustomMap setLatitudeLongitude={setLatitudeLongitude}/>
      </APIProvider>
    </div>
    <div  class="elementdiv" style={{paddingLeft:"20%", paddingRight:"20%", paddingBottom:"2%"}}>
    <label>
    <text class="label">Upload a PDF (2+ pages) with answers to potential stakeholder questions. Include sections on who you are, what you seek, and your contact details. Keep the file safe for future updates.</text>
    </label>
    </div>
      <input type="file" accept="application/pdf" onChange={handleFileChange}/>
      <div class="elementdiv">
      <div class="verticalscrolldiv">
      {text}
      </div>
      </div>

      <div  class="elementdiv">
      <button className="form-button" onClick={handleSubmit}>Submit</button>
      </div>
        

        </body>
    )
}

export default Profile