import React from 'react'


const Home = () => {

    return (
        
        <body style={{textAlign:"center"}}>
            <div class="container-fluid bg-breadcrumb">
        <div class="bg-breadcrumb-single"></div>
        <div class="container text-center py-5" style={{maxWidth:'900px'}}>
            <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">In My Colony</h4>
               
        </div>
    </div>
        

        <div style={{textAlign:"center",paddingLeft:"20%", paddingRight:"20%", paddingTop:"5%"}}>
        <div  class="elementdiv">
    <label>
    <text class="label">Our goal is to promote Community-based learning (CBL). If you are a student or a community organization or a tutor or a business giving 
    students hands-on opportunities to address real community needs then</text>
    </label>
</div>
        
<div  class="elementdiv"  style={{textAlign:"center",fontSize:"32px"}}>
                   <b> Login, Create profile and Collaborate</b>
                    </div>
                </div>
                <div style={{textAlign:"left",paddingLeft:"20%", paddingRight:"20%", paddingTop:"4%"}}>
        
        <h4 class="label">FAQs</h4>
        
    </div>
    <div  class="elementdiv" style={{textAlign:"left"}}>
                <details>
                <summary style={{paddingLeft:"20%", paddingRight:"20%"}}>What is community-based learning (CBL)?</summary>
  <p class="label" style={{paddingLeft:"20%", paddingRight:"20%"}}>Community-based learning (CBL) is an educational approach that engages students with their communities to enhance their learning 
through real-world experiences. This model connects classroom learning with practical experiences in local settings,
 helping students apply what they’ve learned to make meaningful contributions. 
 It often involves collaboration with community organizations, businesses, or tutors, giving students hands-on 
 opportunities to address real community needs.</p>
</details>
</div>
<div  class="elementdiv"  style={{textAlign:"left"}}>
                <details>
                <summary style={{paddingLeft:"20%", paddingRight:"20%"}}>What are the advantages of CBL?</summary>
  <p class="label" style={{paddingLeft:"20%", paddingRight:"20%"}}>In community-based learning, students benefit from:

[1] Experiential Learning: They gain practical skills and a deeper understanding of the subject by working on real-life projects, 
from environmental initiatives to social service programs.
[2] Social Awareness: Students become more aware of societal issues, developing empathy and a sense of responsibility towards their community.
[3] Collaboration: By working with peers and community members, students enhance their communication, teamwork, and problem-solving skills.
[4] Personal Growth: The process fosters self-confidence, civic-mindedness, and a sense of purpose as students see the direct 
impact of their contributions.</p>
</details>
</div>
<div  class="elementdiv" style={{textAlign:"left"}}>
                <details>
                <summary style={{paddingLeft:"20%", paddingRight:"20%"}}>What are the examples of CBL?</summary>
  <p class="label" style={{paddingLeft:"20%", paddingRight:"20%"}}>Examples of community-based learning include organizing a local clean-up drive, tutoring younger students, 
working with schools, cultural organizations, sports and fitness centers or partnering with local businesses on projects. For instance, farmers can share sustainable practices, or artisans can teach traditional crafts. 
This approach helps students connect academic concepts to real-world applications, making learning more relevant and impactful.</p>
</details>
</div>
        
        </body>
    )
}

export default Home