import React from 'react'
import "./login.css"
import axios from "axios";

const Login = () => {

    const api_node_prod = axios.create({
        baseURL:'https://server-chi-puce.vercel.app'
      })
      
      const api_node_local = axios.create({
      baseURL:'http://localhost:6005'
      })
      
      const api_node = api_node_prod

    const loginwithgoogle = () => {
        window.open(api_node.defaults.baseURL+"/auth/google/callback","_self")
    }
    return (
        <body>
        <div className='login-page'>

            <h1 style={{textAlign:"center"}}>Login</h1>
            <div className='form'>
                <button className='login-with-google-btn' onClick={loginwithgoogle}>
                    Sign In With Google
                </button>
            </div>
        </div>
        </body>
    )
}

export default Login