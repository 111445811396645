import axios from "axios";
import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Community from "./Community";
import Questions from "./Questions";
import Answers from "./Answers";
import Business from "./Business";
import Tutor from "./Tutor";
import Volunteer from "./Volunteer";


function TabPanel(props) {
  

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  

const Dashboard = () => {
  const api_node_prod = axios.create({
    baseURL:'https://server-chi-puce.vercel.app'
  })
  
  const api_node_local = axios.create({
  baseURL:'http://localhost:6005'
  })
  
  const api_node = api_node_prod
    const navigate=useNavigate();
    const [userdata, setUserdata] = useState('');
    const getUser = async ()=> {
        try {
            const response = await api_node.get("/login/success",{withCredentials:true});
            setUserdata(response.data.user["googleId"])
         
          const userdetailsresponse = await api_node.get('/dashboard/details', {
            params: {
             userid:response.data.user["googleId"]
           }
         })

           if(!userdetailsresponse.data["userdoc"]){
              navigate("/profile")
            }
        }catch(error){
          alert("Allow Third Party Cookies for the Website to Work Correctly")
            navigate("*")
        }
    }

    useEffect(()=>{
        getUser()
    },[])

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
      


    return (
        <body style={{textAlign:"center",overflow:"auto"}}>
 <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 400 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="News" {...a11yProps(0)} />
        <Tab label="Volunteer" {...a11yProps(1)} />
        <Tab label="Tutor" {...a11yProps(2)} />
        <Tab label="Community" {...a11yProps(3)} />
        <Tab label="Business" {...a11yProps(4)} />
        <Tab label="Questions Asked" {...a11yProps(5)} />
        <Tab label="Answered" {...a11yProps(6)} />
        
      </Tabs>
      <TabPanel value={value} index={0}>
      News
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Volunteer userdata={userdata}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Tutor userdata={userdata}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Community userdata={userdata}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Business userdata={userdata}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Questions userdata={userdata}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <Answers userdata={userdata}/>
      </TabPanel>
      
    </Box>
        </body>
    )
}

export default Dashboard