import React, {useEffect, useState} from 'react';
import "./elements.css";
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { useLocation,useNavigate } from "react-router-dom";
import {BounceLoader} from 'react-spinners';
import ReactMarkdown from 'react-markdown';
import SearchMap from "./SearchMap";
import { APIProvider } from "@vis.gl/react-google-maps";


const Search = () => {

  const api_python_prod = axios.create({
    baseURL:'https://genaiapp-e46e4a1cbba8.herokuapp.com'
})

const api_python_local = axios.create({
  baseURL:'http://localhost:8000'
})

const api_python = api_python_prod

const api_node_prod = axios.create({
  baseURL:'https://server-chi-puce.vercel.app'
})

const api_node_local = axios.create({
baseURL:'http://localhost:6005'
})

const api_node = api_node_prod

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [commonquestions, setCommonquestions] = useState('');

    const location = useLocation();
    const navigate=useNavigate();
  const data = location.state; // Access the passed data
    const [userdata, setUserdata] = useState({});
    

    const getUser = async()=> {
      try {
          const response = await api_node.get("/login/success",{withCredentials:true});
          setUserdata(response.data.user)
          if(data==null){
              navigate("*")
          }
      }catch(error){
          navigate("*")
      }
  }

  const getCommonQuestions = async()=> {
    try {
      
        const response = await api_python.get("/commonquestions",{
          params: { id: data.id }
         
        });
        setCommonquestions(response.data.answers)
    }catch(error){
        navigate("*")
    }
}

  useEffect(()=>{
      getUser()
  },[])

  useEffect(()=>{
    getCommonQuestions()
},[])

const handleSubmit = async (e) => {
      setAnswer('');
      setIsLoading(true);
      e.preventDefault();
      const response = await api_python.post('/profilechat',{message: question});
      setAnswer(response.data.answer);
      setIsLoading(false);
      const response1 = await api_node.post('/savequestionanswer',{answergoogleId:data.id,question:question, answer:response.data.answer, questiongoogleId:userdata.googleId});
}
   
    return (
        <body style={{textAlign:"center"}}>
   <div class="elementdiv" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={data?.image}
              alt={data?.profileName}
              style={{ width: "80px", height: "80px", borderRadius: "5px" }}
            />
            <div>
              <p style={{ margin: "0", fontSize: "14px", fontWeight:"bold", color: "darkgoldenrod" }}>{data?.profileName}</p>
              <p style={{ margin: "0", fontSize: "12px", fontWeight:"bolder" }}>
                {data?.profileDescription}
              </p>
              <p style={{ margin: "0", fontSize: "12px", color: "#999" }}>
                Distance: {data?.distance/1000} km
              </p>
            </div>
          </div>
          <div  class="elementdiv">
   
    <input style={{width:'40%'}} type="text" value={question} onChange={(e)=> setQuestion(e.target.value)} placeholder='Ask question...'/>
    <button className="form-button" style={{padding: '10px 20px',margin: '15px',fontSize: '16px',borderRadius: '5px',border: 'none',cursor:'pointer',backgroundColor: 'rgb(255,120,30)',color: 'white',boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'}} type="submit" onClick={handleSubmit}>Q&A</button>
   
</div>
<div className='main-container'>
{isLoading && (
           
                  <BounceLoader color="darkgoldenrod" />
           
           )}
            {answer && (<div className='results-answer'>
            <h4>Answer:</h4>
            <ReactMarkdown>
                {answer}
            </ReactMarkdown>
           
           </div>)}

           {commonquestions && (<div className='results-answer'>
            <h4>Who are we?</h4>
            <ReactMarkdown>
                {commonquestions[0]['Who are we?']}
            </ReactMarkdown></div>)}

            {commonquestions && (<div className='results-answer'>
            <h4>What are we looking for?</h4>
            <ReactMarkdown>
                {commonquestions[1]['What are we looking for?']}
            </ReactMarkdown></div>)}

            {commonquestions && (<div className='results-answer'>
            <h4>How to get in touch with us?</h4>
            <ReactMarkdown>
                {commonquestions[2]['How to get in touch with us?']}
            </ReactMarkdown></div>)}

          
</div>

<div className="app">
      <APIProvider apiKey="AIzaSyA0aU-z6e1-cGOEJ6J0kK-l33josqGFYrM">
       {data && (<SearchMap coordinates={data?.coordinates}/>)}
      </APIProvider>
    </div>

        </body>
    )
}

export default Search