import React, {useEffect, useState} from 'react';
import "./header.css";
import { NavLink } from 'react-router-dom';
import axios from "axios";
import logo from '../logo.png';
import namaste from '../namaste.jpg';
import "./lib/animate/animate.min.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";
import "./lib/lightbox/css/lightbox.min.css";
import "./css/bootstrap.min.css";
import "./css/style.css";



const Headers = () => {
    const api_node_prod = axios.create({
        baseURL:'https://server-chi-puce.vercel.app'
      })
      
      const api_node_local = axios.create({
      baseURL:'http://localhost:6005'
      })
      
      const api_node = api_node_prod
    const [userdata, setUserdata] = useState({});

    const getUser = async()=> {
        try {
            const response = await api_node.get("/login/success",{withCredentials:true});
            setUserdata(response.data.user)
        }catch(error){
           console.log("error",error)
        }
    }

    const logout=()=>{
        window.open(api_node.defaults.baseURL+"/logout","_self")
    }

    useEffect(()=>{
        getUser()
    },[])
    return (
        <>
        <header>

            <nav>

                <div className='left'>
                    <img src={logo} width={100} height={100} />
                </div>
                <div className='right'>

                    <ul>

                        <li><NavLink to="/">Home</NavLink></li>
                        { 
                            Object?.keys(userdata)?.length > 0 ? (
        
                                <>
                                 <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                    
                                 <li><NavLink to="/profile"><img src={namaste} style={{width:"20px", borderRadius:"50%"}} /> {userdata?.displayName}</NavLink></li>
                                <li><NavLink to="/profile"><img src={userdata?.image} style={{width:"50px", borderRadius:"50%"}} alt="PIC" referrerPolicy="no-referrer"/></NavLink></li>
                                <li onClick={logout}>Logout</li>
                                </>
                            ):<li><NavLink to="/login">Login</NavLink></li>
                        }
                        
                        
                    </ul>
                </div>
            </nav>
        </header>
        </>
    )
}

export default Headers