import "./map.css";
import React, { useState } from "react";
import { Map, Marker, InfoWindow} from "@vis.gl/react-google-maps";

const CustomMap = ({setLatitudeLongitude}) => {
  // shows marker on London by default
  const [markerLocation, setMarkerLocation] = useState({
    lat: 23.179300,
    lng: 75.784912,
  });


  // handle click on map
const handleMapClick = (mapProps) => {
    // checks if location clicked is valid
    if (mapProps.detail.placeId) {
      const lat = mapProps.detail.latLng.lat;
      const lng = mapProps.detail.latLng.lng;
      setLatitudeLongitude([lat,lng]);
    } else {
      // show alert message
      alert("Please select the specific location");
    }
  };

  


  return (
    <div className="map-container">
      <Map
        style={{ borderRadius: "20px" }}
        defaultZoom={3.5}
        defaultCenter={markerLocation}
        gestureHandling={"greedy"}
        onClick={(mapProps) => handleMapClick(mapProps)}
      >
        
      </Map>
    </div>
  );
}

export default CustomMap;