import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {BounceLoader} from 'react-spinners';

const Community = (prop) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const api_node_prod = axios.create({
    baseURL:'https://server-chi-puce.vercel.app'
  })
  
  const api_node_local = axios.create({
  baseURL:'http://localhost:6005'
  })
  
  const api_node = api_node_prod
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await api_node.get(`/dashboard/community?page=${currentPage}&limit=${itemsPerPage}&userid=${prop.userdata}`);
        setData(response.data.docs);
        setTotalPages(response.data.totalpages);
        setMessage(response.data.message);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage]);

  // Logic for displaying pagination buttons
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

   const handleClick = (id,coordinates,profileName, profileDescription, distance, image) => {

    const data = {id,coordinates,profileName, profileDescription, distance, image}; // Example data to pass
    navigate("/search", { state: data });


   }
  return (
    <div>
    {/* Display your data here */}
    {isLoading && (
           
           <BounceLoader color="darkgoldenrod" />
    
    )}
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {data.map((item) => (
        <div
          key={item.googleId}
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleClick(item.googleId,item.loc.coordinates,item.profileName, item.profileDescription, item.distance, item.image)}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={item.image}
              alt={item.profileName}
              style={{ width: "80px", height: "80px", borderRadius: "5px" }}
            />
            <div>
              <p style={{ margin: "0", fontSize: "14px", fontWeight:"bold", color: "darkgoldenrod" }}>{item.profileName}</p>
              <p style={{ margin: "0", fontSize: "12px", fontWeight:"bolder" }}>
                {item.profileDescription}
              </p>
              <p style={{ margin: "0", fontSize: "12px", color: "#999" }}>
                Distance: {item.distance/1000} km
              </p>
            </div>
          </div>
         
        </div>
      ))}
    </div>
    {/* Display message */}
    <div style={{ margin: "20px"}}>
{message}
      </div>
    {/* Pagination buttons */}
    <div style={{ margin: "20px", paddingBottom:"60px"}}>
      {pageNumbers.map((number) => (
        <button key={number} onClick={() => setCurrentPage(number)}>
          {number}
        </button>
      ))}
    </div>
  </div>

  );
}

export default Community;